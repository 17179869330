<template>
  <a-card :bordered="false">
    <div>
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="订单状态记录"
        @back="() => $router.go(-1)"
      >
        <a-row type="flex">
        </a-row>
      </a-page-header>
      <s-table
        ref="table"
        size="middle"
        rowKey="id"
        :show-pagination="false"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
      </s-table>
    </div>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import { customs_order_goods_status } from '@/api/customs_order'

export default {
  name: 'OrderReadList',
  components: {
    STable
  },
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      confirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          customRender: (text) => text === '1970-01-01' ? '无' : text
        },
        {
          title: '订单号',
          dataIndex: 'order_no'
        },
        {
          title: '返回信息',
          dataIndex: 'return_info'
        },
        {
          title: '返回状态',
          dataIndex: 'return_status',
          customRender: (text) => this.$Dictionaries.order_return_status[text] || text
        },
        {
          title: '返回时间',
          dataIndex: 'return_time'
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', this.record)
        // this.record.order_no
        return customs_order_goods_status(this.$route.params.order_no)
          .then(res => {
            console.log(res.data, '我是数据列表@')
            const obj = {
              entries: res.data
            }
            return obj
          })
      }
    }
  },
  created () {
    console.log(this.$Dictionaries)
  },
  methods: {
    handleAddCancel () {
      this.visible = false

      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    }
  }
}
</script>
